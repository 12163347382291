@use '@angular/material' as mat;

//Angular Material Components

@import './styles/am-components/am-progress-bar';

// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-typography-config());`
@include mat.all-component-typographies();
@include mat.core();

$waltio-primary-palette: (
  50: #e4e4fc,
  100: #babaf9,
  200: #8d8df5,
  300: #5f5ff0,
  400: #3c3ced,
  500: #1a1aea,
  600: #1717e7,
  700: #1313e4,
  800: #0f0fe1,
  900: #0808db,
  A100: #ffffff,
  A200: #d1d1ff,
  A400: #9e9eff,
  A700: #8585ff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$waltio-warn-palette: (
  50: #fce4ec,
  100: #f9bad0,
  200: #f58db0,
  300: #f05f90,
  400: #ed3c79,
  500: #e85051,
  600: #e71759,
  700: #e4134f,
  800: #e10f45,
  900: #db0833,
  A100: #ffffff,
  A200: #ffd1d8,
  A400: #ff9eae,
  A700: #ff8598,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$waltio-primary: mat.define-palette($waltio-primary-palette);
$waltio-accent: mat.define-palette(mat.$orange-palette);
$waltio-warn: mat.define-palette($waltio-warn-palette);

$waltio-theme: mat.define-light-theme(
  (
    color: (
      primary: $waltio-primary,
      accent: $waltio-accent,
      warn: $waltio-warn,
    ),
  )
);

$waltio-typography: mat.define-typography-config(
  $font-family: 'Roobert',
  $headline-1:
    mat.define-typography-level(
      $font-weight: 400,
      $font-size: 14px,
      $line-height: 1,
      $letter-spacing: normal,
    ),
  $headline-2:
    mat.define-typography-level(
      $font-weight: 400,
      $font-size: 14px,
      $line-height: 1,
      $letter-spacing: normal,
    ),
  $headline-3:
    mat.define-typography-level(
      $font-weight: 400,
      $font-size: 14px,
      $line-height: 1,
      $letter-spacing: normal,
    ),
  $headline-4:
    mat.define-typography-level(
      $font-weight: 400,
      $font-size: 14px,
      $line-height: 1,
      $letter-spacing: normal,
    ),
  $headline-5:
    mat.define-typography-level(
      $font-weight: 400,
      $font-size: 14px,
      $line-height: 1,
      $letter-spacing: normal,
    ),
  $headline-6:
    mat.define-typography-level(
      $font-family: 'Roobert-SemiBold',
      $font-weight: 500,
      $font-size: 20px,
      $line-height: 1,
      $letter-spacing: normal,
    ),
  $subtitle-1:
    mat.define-typography-level(
      $font-weight: 400,
      $font-size: 14px,
      $line-height: 1,
      $letter-spacing: normal,
    ),
  $subtitle-2:
    mat.define-typography-level(
      $font-weight: 400,
      $font-size: 14px,
      $line-height: 1,
      $letter-spacing: normal,
    ),
  $body-1:
    mat.define-typography-level(
      $font-weight: 400,
      $font-size: 14px,
      $line-height: 1,
      $letter-spacing: normal,
    ),
  $body-2: mat.define-typography-level(v),
  $caption:
    mat.define-typography-level(
      $font-weight: 400,
      $font-size: 14px,
      $line-height: 1,
      $letter-spacing: normal,
    ),
  $button:
    mat.define-typography-level(
      $font-weight: 400,
      $font-size: 14px,
      $line-height: 1,
      $letter-spacing: normal,
    ),
);

@include mat.all-component-typographies($waltio-typography);
@include mat.all-component-themes($waltio-theme);

* {
  font-family: 'Roobert', sans-serif;
}

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}

.f-row {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.mat-mdc-unelevated-button.mat-primary {
  border-radius: 44px;
}

.pointer {
  cursor: pointer;
}

.coin-icon {
  border-radius: 50%;
}
