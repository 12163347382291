@font-face {
  font-family: 'Roobert';
  src: url('./Roobert-Regular.woff');
}

@font-face {
  font-family: 'Roobert-Bold';
  src: url('./Roobert-Bold.woff');
}

@font-face {
  font-family: 'Roobert-Heavy';
  src: url('./Roobert-Heavy.woff');
}

@font-face {
  font-family: 'Roobert-Light';
  src: url('./Roobert-Light.woff');
}

@font-face {
  font-family: 'Roobert-Medium';
  src: url('./Roobert-Medium.woff');
}

@font-face {
  font-family: 'Roobert-Regular';
  src: url('./Roobert-Regular.woff');
}

@font-face {
  font-family: 'Roobert-SemiBold';
  src: url('./Roobert-SemiBold.woff');
}
