.mat-mdc-progress-bar.custom-progress-bar {
  &--success {
    .mdc-linear-progress__buffer-bar {
      background-color: transparent;
    }

    .mdc-linear-progress__bar-inner {
      border-color: #19c785;
    }
  }

  &--error {
    .mdc-linear-progress__buffer-bar {
      background-color: transparent;
    }

    .mdc-linear-progress__bar-inner {
      border-color: #ee9596;
    }
  }
}
